import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Main'
import Admin from './Admin.js';
import Edit from './Edit.js';
import Create from './Create.js';
import { io } from 'socket.io-client';
import HOST_URL from './config';

const socket = io(HOST_URL);


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main socket={socket}/>} />
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/item/:id" element={<Edit/>}/>
        <Route path="/create" element={<Create/>}/>
      </Routes>
    </Router>
  )
  }

export default App;
