import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';

export default function Edit() {
    const navigate = useNavigate();
    const {id} =useParams();
    const [data,setData] = useState([]);
    const [menu,setMenu] = useState('breakfast')
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        axios.get(HOST_URL + '/menuitems/list/' + id).then(res=>{
          setData(res.data)
          setLoading(false)
        }).catch(error=>{
          console.log(error);
        })
      },[])
      const hc = async() => {
        try {
        const cass = await axios.post(HOST_URL + '/menuitems/update/' + id,
            {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: (data),
              }
        )
        console.log('THE CASS',cass)
    } catch(error) {
        console.log(error)
    }

      }
      return loading ? (<div>loading</div>):(
        <div>
            <div>edit : {id}</div>
            <div>
                {/* {Object.keys(data).map((key) => {
                  console.log(`Key: ${key}, Value: ${data[key]}`);
                  return (
                    <div>ss{`Key: ${key}, Value: ${data[key]}`}</div>
                  )
                })} */}
            </div>
            <div className="editinputs">
                <div className="inputwrap">
                    <div className="inputname">name</div>
                    <input value={data.name} onChange={(e)=>{
                        setData({...data,name:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">description</div>
                    <textarea value={data.description} onChange={(e)=>{
                        setData({...data,description:e.target.value})
                    }}/>
                </div>

                <div className="inputwrap">
                    <div className="inputname">price</div>
                    <input value={data.price} onChange={(e)=>{
                        setData({...data,price:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">menu</div>
                    <input value={data.menu} onChange={(e)=>{
                        setData({...data,menu:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">menu2</div>
                    <input value={data.menu2} onChange={(e)=>{
                        setData({...data,menu2:e.target.value})
                    }}/>
                </div>

            </div>
            <div className="button" onClick={()=>{navigate(-1)}}>go back</div>
            <div className="button" onClick={()=>{hc()}}>update</div>
        </div>
    )
}