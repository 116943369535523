import React, {useEffect,useState} from 'react';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';



function Admin() {
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [menu,setMenu] = useState('breakfast')
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
      axios.get(HOST_URL + '/menuitems/listall').then(res=>{
        console.log('DATA',res.data)
        setData({list:res.data.list,menu:res.data.menu})
        setLoading(false)
      }).catch(error=>{
        console.log(error);
      })
    },[])

    return loading ? (<div>loading</div>) : (
        <div>
            {data.list.map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.soldout ? 'red' : 'lightblue'
                    }}
                        onClick={()=>{
                            console.log('THE D',d._id)
                            navigate(`/item/${d._id}`);
                        }}
                    >
                        <div className="itemmenu i">{d.menu}</div>
                        <div className="itemname i">{d.name}</div>
                        <div className="itemdesc i">{d.description}</div>
                        <div className="itemprice i">{d.price}</div>
                    </div>
                )
            })}
        <div className="button" onClick={()=>{navigate('/create')}}>create</div>
        </div>
    )
}


export default Admin;