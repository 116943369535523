import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';

export default function Create() {
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
      },[])
      const hc = async() => {
        try {
        const cass = await axios.post(HOST_URL + '/menuitems/create/',
            {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: (data),
              }
        )
        console.log('THE CASS',data)
    } catch(error) {
        console.log(error)
    }

      }
      return loading ? (<div>loading</div>):(
        <div>
            <div>create : </div>
            <div className="editinputs">
                <div className="inputwrap">
                    <div className="inputname">name</div>
                    <input value={data.name} onChange={(e)=>{
                        setData({...data,name:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">description</div>
                    <textarea value={data.description} onChange={(e)=>{
                        setData({...data,description:e.target.value})
                    }}/>
                </div>

                <div className="inputwrap">
                    <div className="inputname">price</div>
                    <input value={data.price} onChange={(e)=>{
                        setData({...data,price:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">menu</div>
                    <input value={data.menu} onChange={(e)=>{
                        setData({...data,menu:e.target.value})
                    }}/>
                </div>
                <div className="inputwrap">
                    <div className="inputname">menu2</div>
                    <input value={data.menu2} onChange={(e)=>{
                        setData({...data,menu2:e.target.value})
                    }}/>
                </div>

            </div>
            <div className="button" onClick={()=>{navigate(-1)}}>go back</div>
            <div className="button" onClick={()=>{hc()}}>create</div>
        </div>
    )
}