import React, {useEffect,useState} from 'react';
import axios from 'axios';
import HOST_URL from './config';
import { useNavigate } from 'react-router-dom';

console.log('Current Host:', HOST_URL);


function Main(props) {
    const navigate = useNavigate();
const {socket} = props;
console.log('the sockets',socket)
    const [data,setData] = useState([]);
    const [menu,setMenu] = useState('breakfast')
    useEffect(()=>{
      axios.get(HOST_URL + '/menuitems/listall').then(res=>{
        console.log('DATA',res.data)
        setData({list:res.data.list,menu:res.data.menu})
      }).catch(error=>{
        console.log(error);
      })
    },[])
    return data.list ? (
        <div className="container">
            <div className="admin" onClick={()=>{
                navigate('/admin')
            }}>admin</div>
            <div className="menuselect">
                <select
                    onChange={(e)=>{
                        setMenu(e.target.value);

                    }}
                    value={menu}
                >
                    <option value="breakfast">breakfast</option>
                    <option value="lunch">lunch</option>
                    <option value="dinner">dinner</option>
                    <option value="closedmenu">closed w/ menu</option>
                    <option value="closedblank">closed & blank</option>
                </select>
                    <div className="updatemenu"
                        onClick={()=>{
                            console.log('the menu is',menu)
                            axios.post(HOST_URL + '/config/setmode',{screenmode:menu}).then(res=>{
                                console.log('done',res.data);
                                axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                    console.log('DATA',res.data)
                                    setData({list:res.data.list,menu:res.data.menu})
                                  }).catch(error=>{
                                    console.log(error);
                                  })
                            }).catch(error=>{
                                console.log(error)
                            })
                        }}
                    >update menu</div>
                    <div className="updatemenu"
                        onClick={()=>{
                            console.log('reloading')
                            axios.get(HOST_URL + '/menuitems/reload').then(res=>{
                                console.log('done',res.data);
                            }).catch(error=>{
                                console.log(error)
                            })
                        }}
                    >refresh menu</div>

            </div>
            <div className="toggle_menu">Menu is {data.menu}</div>
            {data.list.map(d=>{
                return (
                    <div key={d._id} className="itemwrap" style={{
                        backgroundColor:d.soldout ? 'red' : 'lightblue'
                    }}
                        onClick={()=>{
                            console.log('THE D',d._id)
                            const jsondata = {
                                id:d._id
                            }
                            axios.post(HOST_URL + '/menuitems/toggle',jsondata,{
                                headers : {
                                    'Content-Type': 'application/json',
                                }
                            }).then(res=>{
                                console.log('toggles the res',res.data)
                                axios.get(HOST_URL + '/menuitems/listall').then(res=>{
                                    console.log('DATA',res.data)
                                    setData({list:res.data.list,menu:res.data.menu})
                                  }).catch(error=>{
                                    console.log(error);
                                  })
                            }).catch(error=>{
                                console.log('error',error)
                            })
                        }}
                    >
                        <div className="itemmenu i">{d.menu}</div>
                        <div className="itemname i">{d.name}</div>
                        <div className="itemdesc i">{d.description}</div>
                        <div className="itemprice i">{d.price}</div>
                    </div>
                )
            })}
        </div>
    ) : (<div>mnull</div>)
}

export default Main;